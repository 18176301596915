import * as React from 'react';
import Main from '../components/main';
import styled from 'styled-components';
import { Root, Footer } from '../styles';
import Title from '../components/title';

// Step 2: Define your component
const IndexPage = () => {
  return (
    <>
      {' '}
      <Title title={'Juicer'}></Title>
      <Root>
        <Main></Main>
        <Footer>@2022 | Powered by Enthusiasm & ❤️</Footer>
      </Root>
    </>
  );
};

// Step 3: Export your component
export default IndexPage;
