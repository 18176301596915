import { profile } from 'console';
import * as React from 'react';
import tw from 'twin.macro';

import { A, Paragraph, ParagraphWrapper } from '../styles';

interface props {
  resumeLink: string;
  githubLink: string;
}

const P = tw.p`m-0`;
const Profile = ({ resumeLink, githubLink }: props) => {
  return (
    <ParagraphWrapper>
      {' '}
      <Paragraph>
        <P>
          Enthusiastic about solving problems, all kinds of, especially
          impactful ones.
        </P>
        <P>
          {' '}
          <A href={githubLink} target='_blank' rel='noopener'>
            GitHub
          </A>{' '}
          | <A href='#'>Resume (WIP)</A> | <A href='#'>Projects (WIP)</A>
        </P>
      </Paragraph>
      <Paragraph>
        <P>Keep learning, keep enthusiastic.</P>
      </Paragraph>
    </ParagraphWrapper>
  );
};
export default Profile;
