import { useStaticQuery, graphql } from 'gatsby';
import { SiteMetadata } from '../models/SiteMetadata';
import * as React from 'react';
import Bar from './bar';
import Profile from './profile';
import { Container } from '../styles';

interface InitialData {
  site: { siteMetadata: SiteMetadata };
}

const query = graphql`
  query medaDataQuery {
    site {
      siteMetadata {
        title
        avatar
        name
        selfIntro
        resumeLink
        githubLink
      }
    }
  }
`;
const Main = () => {
  const data: InitialData = useStaticQuery(query);
  return (
    <Container>
      <Bar
        avatarLink={data.site.siteMetadata.avatar}
        selfIntro={data.site.siteMetadata.selfIntro}
        name={data.site.siteMetadata.name}
      ></Bar>
      <Profile
        resumeLink={data.site.siteMetadata.resumeLink}
        githubLink={data.site.siteMetadata.githubLink}
      ></Profile>
    </Container>
  );
};

export default Main;
