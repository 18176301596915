import * as React from 'react';
import styled from 'styled-components';
import {
  Avatar,
  AvatarWrapper,
  SummaryContainer,
  SummaryContent,
  SummarySubtitle,
  SummarySubtitleWrapper,
  SummaryTitle,
  SummaryTitleWrapper,
} from '../styles';

const MyBar = styled.div`
  flex: flex-col;
  display: flex;
`;

interface BarProps {
  avatarLink: string;
  name: string;
  selfIntro: string;
}

const Bar = ({ avatarLink, name, selfIntro }: BarProps) => {
  return (
    <SummaryContainer>
      <AvatarWrapper>
        <Avatar src={avatarLink} />
      </AvatarWrapper>
      <SummaryContent>
        <SummaryTitleWrapper>
          <SummaryTitle>{name}</SummaryTitle>
          {/* <SummaryTitleAp>E53E D56B 7F20 B7BB</SummaryTitleAp> */}
        </SummaryTitleWrapper>
        <SummarySubtitleWrapper>
          <SummarySubtitle>{selfIntro}</SummarySubtitle>
        </SummarySubtitleWrapper>
      </SummaryContent>
    </SummaryContainer>
  );
};

export default Bar;
